<template>
  <div class="switching-add has-nav-bar">
    <van-nav-bar
      :border="false"
      class="switching-nav"
      left-arrow
      fixed
      @click-left="$router.back()"
    >
      <div slot="title" class="nav-title">
        {{ form.statusName || "联锁投切申请" }}
      </div>
    </van-nav-bar>

    <LoadView v-model="loadStatus" style="width: 100%;">
      <van-form
        v-if="loadStatus === LOAD_STATUS.SUCCESS"
        ref="form"
        class="content-box"
        validate-trigger="onSubmit"
      >
        <!-- 所属部门 -->
        <DepartmentFormItem v-model="form.department" :data="form" />
        <!-- 联锁回路名称 -->
        <ChainAccountFormItem v-model="form.chainAccountId" :data="form" />
        <!-- 联锁回路位号 -->
        <ChainCircuitBitFormItem v-model="form.chainCircuitBit" :data="form" />
        <!-- 联锁等级 -->
        <van-field
          readonly
          :value="form.chainLevelName"
          name="chainLevelName"
          label="联锁等级"
          input-align="right"
          error-message-align="right"
        />

        <!-- 联锁回路功能描述 -->
        <van-field
          v-model="form.chainCircuitDescription"
          rows="3"
          autosize
          label="联锁回路功能描述"
          type="textarea"
          maxlength="2000"
          placeholder="请输入联锁回路功能描述"
          show-word-limit
          required
          :rules="[
            {
              required: true,
              message: `请输入联锁回路功能描述`,
              trigger: 'onBlur'
            }
          ]"
        />

        <!-- 上传附件	 -->
        <SwitchingFileUploader
          v-model="form.attachments"
          accept="application/pdf,image/png,image/jpeg"
          :max-size="1024 * 1024 * 10"
          tip="只能上传pdf,png,jpeg文件, 且文件大小不超过 10M"
        />

        <!-- 投切类型 -->
        <van-field label="投切类型">
          <template #input>
            <van-radio-group
              v-model="form.changeType"
              direction="horizontal"
              @change="onTypeChange"
            >
              <van-radio
                v-for="item in LOCK_TYPE"
                :key="item.value"
                :name="item.value"
                style="padding: 2px 0;"
                >{{ item.label }}</van-radio
              >
            </van-radio-group>
          </template>
        </van-field>

        <!-- 停用性质 -->
        <van-field v-if="!form.changeType" key="stopStatus" label="停用性质">
          <template #input>
            <van-radio-group
              v-model="form.stopStatus"
              direction="horizontal"
              @change="onStopTypeChange"
            >
              <van-radio
                v-for="item in DECOMMISSIONS"
                :key="item.value"
                :name="item.value"
                style="padding: 2px 0;"
                >{{ item.label }}</van-radio
              >
            </van-radio-group>
          </template>
        </van-field>

        <!-- 投用/停用原因 -->
        <van-field
          key="reason"
          v-model="form.reason"
          rows="3"
          required
          autosize
          :label="`${form.changeType ? '投用' : '停用'}原因`"
          type="textarea"
          maxlength="500"
          :placeholder="`请输入${form.changeType ? '投用' : '停用'}原因`"
          show-word-limit
          :rules="[
            {
              required: true,
              message: `请输入${form.changeType ? '投用' : '停用'}原因`,
              trigger: 'onBlur'
            }
          ]"
        />

        <!-- 计划停用时间 -->
        <TimePickerFormItem
          v-if="!form.changeType"
          key="planStopTime"
          v-model="form.planStopTime"
          label="计划停用时间"
          is-link
          readonly
          name="planStopTime"
          placeholder="请选择"
          required
          input-align="right"
          error-message-align="right"
          :min-date="minPlanStopTime"
          :rules="[
            {
              required: true,
              message: `请选择计划停用时间`,
              trigger: 'onBlur'
            }
          ]"
          @input="onPlanStopTimeChange"
        />

        <!-- 计划投用时间 -->
        <TimePickerFormItem
          v-if="form.stopStatus != 2"
          key="planUseTime"
          v-model="form.planUseTime"
          label="计划投用时间"
          is-link
          readonly
          name="planUseTime"
          placeholder="请选择"
          required
          input-align="right"
          error-message-align="right"
          :min-date="minPlanUseTime"
          :max-date="maxPlanUseTime"
          :rules="[
            {
              required: true,
              message: `请选择计划投用时间`,
              trigger: 'onBlur'
            },
            planUseTimeRule
          ]"
        />

        <!-- 停用风险控制方案 -->
        <van-field
          v-if="!form.changeType"
          key="stopRiskScheme"
          v-model="form.stopRiskScheme"
          rows="3"
          required
          autosize
          label="停用风险控制方案"
          type="textarea"
          maxlength="500"
          placeholder="请输入停用风险控制方案"
          show-word-limit
          :rules="[
            {
              required: true,
              message: '请输入停用风险控制方案',
              trigger: 'onBlur'
            }
          ]"
        />

        <van-field label="申请机构">
          <template #input>
            {{ form.orgName }}
          </template>
        </van-field>

        <van-field label="申请人">
          <template #input>
            {{ form.applyUserName }}
          </template>
        </van-field>
      </van-form>
    </LoadView>

    <BottomFixedBox>
      <van-button
        class="confirm-btn"
        round
        size="small"
        type="info"
        @click="onSave()"
        >保 存</van-button
      >
      <van-button
        class="confirm-btn"
        round
        size="small"
        type="info"
        @click="onSubmit()"
        >提 交</van-button
      >
    </BottomFixedBox>
  </div>
</template>

<script>
import { Dialog } from "vant";
import LoadView from "@/components/loadview/LoadView";
import BottomFixedBox from "@/components/general/BottomFixedBox";
import DepartmentFormItem from "./components/DepartmentFormItem.vue";
import ChainAccountFormItem from "./components/ChainAccountFormItem.vue";
import ChainCircuitBitFormItem from "./components/ChainCircuitBitFormItem.vue";
import SwitchingFileUploader from "./components/form/SwitchingFileUploader.vue";
import TimePickerFormItem from "./components/TimePickerFormItem.vue";

import { LOAD_STATUS } from "@/components/loadview/utils";
import { LOCK_TYPE, DECOMMISSIONS } from "./utils";
import dayjs from "dayjs";

import {
  getInterlockChangeDetail,
  addDraftChainChange,
  updateDraftChainChange,
  addInterlockChange,
  updateChangeInfo
} from "./api";

export default {
  components: {
    LoadView,
    BottomFixedBox,
    DepartmentFormItem,
    ChainAccountFormItem,
    ChainCircuitBitFormItem,
    SwitchingFileUploader,
    TimePickerFormItem
  },
  data() {
    return {
      loadStatus: LOAD_STATUS.LOADING,
      form: {
        department: "",
        chainAccountId: "",
        chainCircuitBit: "",
        chainLevelName: "",
        chainCircuitDescription: "",
        attachments: [],
        changeType: 0,
        stopStatus: 0,
        reason: "",
        planStopTime: "",
        planUseTime: "",
        stopRiskScheme: "",
        orgName: "",
        applyUserName: ""
      }
    };
  },
  computed: {
    LOAD_STATUS: () => LOAD_STATUS,
    FILE_ACCEPT_TYPES: () => FILE_ACCEPT_TYPES,
    LOCK_TYPE: () => LOCK_TYPE,
    DECOMMISSIONS: () => DECOMMISSIONS,
    id() {
      return this.$route.params.id;
    },
    // 最小计划停用时间（今天开始时间）
    minPlanStopTime() {
      return dayjs()
        .startOf("date")
        .toDate();
    },
    // 最小计划投用时间
    minPlanUseTime() {
      // 不存在停用时间，最小值今天（今天开始时间）
      if (!this.form.planStopTime) return this.minPlanStopTime;
      // 最小值就是停用时间
      if (this.form.stopStatus === 0)
        return dayjs(this.form.planStopTime).toDate();
      // 最小值就是停用时间+72小时
      return dayjs(this.form.planStopTime)
        .add(72, "hour")
        .toDate();
    },
    // 最大计划投用时间
    maxPlanUseTime() {
      // 最大值就是停用时间+72小时
      if (this.form.planStopTime && this.form.stopStatus === 0)
        return dayjs(this.form.planStopTime)
          .add(72, "hour")
          .toDate();
      return undefined;
    },
    planUseTimeMessage() {
      if (this.form.flowIsDelay) return "";
      if (!this.form.changeType && !this.form.planStopTime)
        return "请先选择计划停用时间";

      const planUseTime = new Date(this.form.planUseTime).getTime();
      const planStopTime = new Date(this.form.planStopTime).getTime();
      const disTime = planUseTime - planStopTime;

      if (this.form.planStopTime) {
        if (this.form.stopStatus == 0 && disTime > 72 * 60 * 60 * 1000)
          return "临时解除时间范围应小于72小时，请重新选择时间";
        if (this.form.stopStatus == 1 && disTime < 72 * 60 * 60 * 1000)
          return "长期停用时间范围应大于72小时，请重新选择时间";
      }

      return "";
    },
    // 最小计划投用时间校验规则
    planUseTimeRule() {
      return {
        validator: () => !this.planUseTimeMessage,
        message: this.planUseTimeMessage,
        trigger: "onChange"
      };
    }
  },
  watch: {
    "form.stopStatus"() {
      if (!this.form.changeType) {
        this.form.planUseTime = "";
        this.form.planStopTime = "";
      }
    }
  },
  mounted() {
    if (this.id) {
      this.loadStatus = LOAD_STATUS.LOADING;
      getInterlockChangeDetail(this.id)
        .then(res => {
          if (!res) {
            this.loadStatus = LOAD_STATUS.EMPTY;
            return;
          }

          this.chainCircuit = res.chainAccountVo;

          this.form = {
            ...res.chainChangeVo,
            departmentName: this.chainCircuit.departmentName,
            chainAccountName: this.chainCircuit.chainCircuitName,
            chainLevelName: this.chainCircuit.chainLevelName
          };

          this.loadStatus = LOAD_STATUS.SUCCESS;
        })
        .catch(err => {
          console.log("err", err);
          this.loadStatus = LOAD_STATUS.ERROR;
        });
    } else {
      this.form.department = this.userInfo.department;
      this.form.departmentName = this.userInfo.departmentName;
      this.form.departmentNames = this.userInfo.departmentName;

      this.form.orgName = this.userInfo.orgName;
      this.form.applyUserName = this.userInfo.nickName;

      this.loadStatus = LOAD_STATUS.SUCCESS;
    }
  },
  methods: {
    onPlanStopTimeChange() {
      this.$nextTick(() => [this.$refs.form.validate("planUseTime")]);
    },
    onTypeChange(type) {
      this.form.stopStatus = type ? null : 0;
    },
    onStopTypeChange() {
      this.form.planUseTime = "";
      this.form.planStopTime = "";
    },
    async onSave() {
      await this.$refs.form.validate();
      await Dialog.confirm({
        title: "操作提示",
        message: "提交后进入审批流程，期间不能编辑，确认提交数据？"
      });
      const data = this.getPostData();
      const postMethods = this.id
        ? updateDraftChainChange
        : addDraftChainChange;
      postMethods(data)
        .then(() => {
          this.$toast.success("保存成功");
          this.$router.back();
        })
        .catch(() => {
          this.$toast.fail("保存失败, 请重试");
        });
    },
    async onSubmit() {
      await this.$refs.form.validate();
      await Dialog.confirm({
        title: "操作提示",
        message: "提交后进入审批流程，期间不能编辑，确认提交数据？"
      });
      const data = this.getPostData();
      const postMethods = this.id ? updateChangeInfo : addInterlockChange;
      postMethods(data)
        .then(() => {
          this.$toast.success("提交成功");
          this.$router.back();
        })
        .catch(() => {
          this.$toast.fail("提交失败, 请重试");
        });
    },
    getPostData() {
      const form = this.form;
      form.stopStatus = form.changeType ? null : form.stopStatus;
      const chainChangeDto = Object.assign({}, form, {
        id: this.id,
        planStopTime: this.form.planStopTime,
        planUseTime: this.form.planUseTime,
        bizTypeId: 1,
        applyUserId: this.userInfo.id,
        orgCode: this.userInfo.orgCode
      });

      return {
        id: this.id,
        chainChangeDto
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.attachments-tip {
  color: #646566;
  font-size: 12px;
  padding-left: 16px;
}
</style>
