<template>
  <van-button class="confirm-btn" round size="small" type="info" @click="onEdit"
    >编 辑</van-button
  >
</template>

<script>
export default {
  methods: {
    onEdit() {
      this.$router.push({
        name: "switching-edit",
        params: { id: this.$route.params.id }
      });
    }
  }
};
</script>
