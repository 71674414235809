import axios from "@/utils/axios";

import apiPath from "@/utils/apiPath";

const warning = apiPath.warning;

// 我的待办
export const getSwitchingListTodo = params => {
  return axios.get(`${warning}/page/chain/change/todo`, { params });
};

// 与我相关
export const getSwitchingListMine = params => {
  return axios.get(`${warning}/page/chain/change/mine`, { params });
};

// 获取我的联锁投切列表
export const getSwitchingList = params => {
  return axios.get(`${warning}/page/chain/change`, { params });
};

// 获取联锁回路名称列表
export const getSelectInterlockLedger = params => {
  return axios.get(`${warning}/list/chain/accounts/tips`, { params });
};

// 获取联锁回路关联的详情
export const getInterLockLedgerDetail = id => {
  return axios.get(`${warning}/info/chain/accounts/${id}`);
};

// 获取联锁回路位号列表
export const getCircuitBitListSelect = chainAccountId => {
  return axios.get(`${warning}/list/circuit/bit/${chainAccountId}`);
};

// 创建连锁投切（草稿）
export const addDraftChainChange = data => {
  return axios.post(`${warning}/draft/chain/change`, data);
};

// 修改连锁投切（草稿）
export const updateDraftChainChange = data => {
  return axios.put(`${warning}/draft/chain/change/${data.id}`, data);
};

// 创建工艺联锁
export const addInterlockChange = data => {
  return axios.post(`${warning}/add/chain/change`, data);
};

// 修改工艺联锁
export const updateChangeInfo = data => {
  return axios.post(`${warning}/update/chain/change/${data.id}`, data);
};

// 获取联锁投切详情
export const getInterlockChangeDetail = id => {
  return axios.get(`${warning}/info/chain/change/${id}`);
};

// 审批流程图
export const getListChainFlowNode = params => {
  return axios.get(`${warning}/list/chain/flow-node`, { params });
};

// 变更审批节点
export const approveChange = data => {
  return axios.post(`${warning}/add/chain/approve`, data);
};

// 操作记录
export const getChangeRecords = params => {
  return axios.get(`${warning}/info/chain/change/record`, { params });
};

// 查询联锁等级字典，不同企业字典不一样
export const getInterlockSelectChainLevel = params => {
  return axios.get(`${warning}/select/chain-level`, { params });
};

// 工作票状态
export const getSelectChangeStatus = () => {
  return axios.get(`${warning}/select/change-status`);
};

// 获取delay信息

export const getChainChangeDelay = params => {
  return axios.get(`${warning}/info/chain/change/delay`, { params });
};
