<template>
  <div class="switching-list has-nav-bar">
    <van-nav-bar
      :border="false"
      class="switching-nav"
      fixed
      left-arrow
      @click-left="$router.back()"
    >
      <div slot="title" class="nav-title">联锁投切查询</div>
    </van-nav-bar>

    <SwitchingList
      ref="switching-list"
      :show-total-view="true"
      :api="getSwitchingList"
    >
      <template slot="header">
        <SwitchingSearch :data="searchData" @on-search="onSearchMineList" />
      </template>
    </SwitchingList>
  </div>
</template>

<script>
import SwitchingList from "./components/SwitchingList.vue";
import SwitchingSearch from "./components/SwitchingSearch.vue";

import { getSwitchingList } from "./api";

export default {
  components: {
    SwitchingList,
    SwitchingSearch
  },
  data() {
    return {
      searchData: {
        keyWord: "",
        department: "",
        chainLevel: "",
        changeType: "",
        stopStatus: "",
        status: ""
      }
    };
  },
  methods: {
    getSwitchingList(query = {}) {
      return getSwitchingList({ ...this.searchData, ...query });
    },
    onSearchMineList() {
      this.$refs["switching-list"].getList(true);
    }
  }
};
</script>
