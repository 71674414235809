<template>
  <div class="switching-process">
    <div
      v-for="(item, index) in steps"
      :key="index"
      class="switching-process-item"
    >
      <div
        class="switching-process-label is-default"
        :class="{
          'is-first': !index,
          'is-error': item.bizFlowNodeStatus === 3,
          'is-finish': item.bizFlowNodeStatus === 2,
          'is-process': item.bizFlowNodeStatus === 1,
          'is-wait': item.bizFlowNodeStatus === 0
        }"
      >
        {{ item.bizFlowName }}
      </div>

      <van-icon
        v-if="item.countersign"
        class="switching-process-icon"
        name="question"
        @click="onDetail(item)"
      />

      <process-arrow
        v-if="index !== steps.length - 1"
        :is-active="index < activeIndex"
        style="margin: 0 auto"
      />
    </div>

    <van-dialog
      v-model="visible"
      :show-confirm-button="false"
      :title="detail.bizFlowName"
      close-on-click-overlay
    >
      <div class="switching-process-dialog">
        <div
          v-for="(bizItem, index) in detailList"
          :key="index"
          class="switching-process-dialog-item"
        >
          <h4 class="switching-process-dialog-item-title">
            {{
              bizItem.level
                ? `${bizItem.level}级${detail.bizFlowName}`
                : `${detail.bizFlowName}`
            }}
          </h4>
          <p class="switching-process-dialog-item-names">
            {{ bizItem.userNames.split(",").join("、") }}
          </p>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import ProcessArrow from "@/views/workticket/components/ProcessArrow";

export default {
  components: {
    ProcessArrow
  },
  props: {
    steps: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      visible: false,
      detail: {}
    };
  },
  computed: {
    activeItem() {
      return this.steps.find(v => v.bizFlowNodeStatus === 1) || {};
    },
    activeIndex() {
      return this.steps.findIndex(v => v.bizFlowNodeStatus === 1) || 0;
    },
    detailList() {
      return this.detail.countersign || [];
    }
  },
  methods: {
    onDetail(item) {
      this.visible = true;
      this.detail = item;
    }
  }
};
</script>

<style lang="scss" scoped>
.switching-process {
  padding: 27px 18px 18px;

  &-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-label {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    font-family: PingFangSC-Regular, PingFang SC;

    &.is-first {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      padding: 0 10px;
      text-align: center;
    }

    &.is-default {
      color: #2e2e4d;
      border: 1px solid #e1e3e8;
    }

    &.is-wait {
      color: #2e2e4d;
      border: 1px solid #e1e3e8;
    }

    &.is-finish {
      color: #2e2e4d;
      background: rgba(22, 118, 255, 0.1);
      border: 1px solid rgba(22, 118, 255, 0.5);
    }

    &.is-process {
      color: #ffffff;
      background: #1676ff;
      border: 1px solid #1676ff;
    }

    &.is-error {
      color: #f53f3f;
      background: rgba(245, 63, 63, 0.1);
      border: 1px solid #f53f3f;
    }
  }

  &-icon {
    color: #c0c4cc;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(106px, -50%);
    font-size: 16px;
  }

  &-dialog {
    padding: 0 20px 20px;
  }
  &-dialog-item {
    margin-top: 8px;
  }

  &-dialog-item-title {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #a0a2a6;
    line-height: 20px;
  }

  &-dialog-item-names {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #242f3b;
    line-height: 20px;
  }
}
</style>
