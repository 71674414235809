var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"switching-add has-nav-bar"},[_c('van-nav-bar',{staticClass:"switching-nav",attrs:{"border":false,"left-arrow":"","fixed":""},on:{"click-left":function($event){return _vm.$router.back()}}},[_c('div',{staticClass:"nav-title",attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.form.statusName || "联锁投切申请")+" ")])]),_c('LoadView',{staticStyle:{"width":"100%"},model:{value:(_vm.loadStatus),callback:function ($$v) {_vm.loadStatus=$$v},expression:"loadStatus"}},[(_vm.loadStatus === _vm.LOAD_STATUS.SUCCESS)?_c('van-form',{ref:"form",staticClass:"content-box",attrs:{"validate-trigger":"onSubmit"}},[_c('DepartmentFormItem',{attrs:{"data":_vm.form},model:{value:(_vm.form.department),callback:function ($$v) {_vm.$set(_vm.form, "department", $$v)},expression:"form.department"}}),_c('ChainAccountFormItem',{attrs:{"data":_vm.form},model:{value:(_vm.form.chainAccountId),callback:function ($$v) {_vm.$set(_vm.form, "chainAccountId", $$v)},expression:"form.chainAccountId"}}),_c('ChainCircuitBitFormItem',{attrs:{"data":_vm.form},model:{value:(_vm.form.chainCircuitBit),callback:function ($$v) {_vm.$set(_vm.form, "chainCircuitBit", $$v)},expression:"form.chainCircuitBit"}}),_c('van-field',{attrs:{"readonly":"","value":_vm.form.chainLevelName,"name":"chainLevelName","label":"联锁等级","input-align":"right","error-message-align":"right"}}),_c('van-field',{attrs:{"rows":"3","autosize":"","label":"联锁回路功能描述","type":"textarea","maxlength":"2000","placeholder":"请输入联锁回路功能描述","show-word-limit":"","required":"","rules":[
          {
            required: true,
            message: "请输入联锁回路功能描述",
            trigger: 'onBlur'
          }
        ]},model:{value:(_vm.form.chainCircuitDescription),callback:function ($$v) {_vm.$set(_vm.form, "chainCircuitDescription", $$v)},expression:"form.chainCircuitDescription"}}),_c('SwitchingFileUploader',{attrs:{"accept":"application/pdf,image/png,image/jpeg","max-size":1024 * 1024 * 10,"tip":"只能上传pdf,png,jpeg文件, 且文件大小不超过 10M"},model:{value:(_vm.form.attachments),callback:function ($$v) {_vm.$set(_vm.form, "attachments", $$v)},expression:"form.attachments"}}),_c('van-field',{attrs:{"label":"投切类型"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('van-radio-group',{attrs:{"direction":"horizontal"},on:{"change":_vm.onTypeChange},model:{value:(_vm.form.changeType),callback:function ($$v) {_vm.$set(_vm.form, "changeType", $$v)},expression:"form.changeType"}},_vm._l((_vm.LOCK_TYPE),function(item){return _c('van-radio',{key:item.value,staticStyle:{"padding":"2px 0"},attrs:{"name":item.value}},[_vm._v(_vm._s(item.label))])}),1)]},proxy:true}],null,false,2762478996)}),(!_vm.form.changeType)?_c('van-field',{key:"stopStatus",attrs:{"label":"停用性质"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('van-radio-group',{attrs:{"direction":"horizontal"},on:{"change":_vm.onStopTypeChange},model:{value:(_vm.form.stopStatus),callback:function ($$v) {_vm.$set(_vm.form, "stopStatus", $$v)},expression:"form.stopStatus"}},_vm._l((_vm.DECOMMISSIONS),function(item){return _c('van-radio',{key:item.value,staticStyle:{"padding":"2px 0"},attrs:{"name":item.value}},[_vm._v(_vm._s(item.label))])}),1)]},proxy:true}],null,false,3060409901)}):_vm._e(),_c('van-field',{key:"reason",attrs:{"rows":"3","required":"","autosize":"","label":((_vm.form.changeType ? '投用' : '停用') + "原因"),"type":"textarea","maxlength":"500","placeholder":("请输入" + (_vm.form.changeType ? '投用' : '停用') + "原因"),"show-word-limit":"","rules":[
          {
            required: true,
            message: ("请输入" + (_vm.form.changeType ? '投用' : '停用') + "原因"),
            trigger: 'onBlur'
          }
        ]},model:{value:(_vm.form.reason),callback:function ($$v) {_vm.$set(_vm.form, "reason", $$v)},expression:"form.reason"}}),(!_vm.form.changeType)?_c('TimePickerFormItem',{key:"planStopTime",attrs:{"label":"计划停用时间","is-link":"","readonly":"","name":"planStopTime","placeholder":"请选择","required":"","input-align":"right","error-message-align":"right","min-date":_vm.minPlanStopTime,"rules":[
          {
            required: true,
            message: "请选择计划停用时间",
            trigger: 'onBlur'
          }
        ]},on:{"input":_vm.onPlanStopTimeChange},model:{value:(_vm.form.planStopTime),callback:function ($$v) {_vm.$set(_vm.form, "planStopTime", $$v)},expression:"form.planStopTime"}}):_vm._e(),(_vm.form.stopStatus != 2)?_c('TimePickerFormItem',{key:"planUseTime",attrs:{"label":"计划投用时间","is-link":"","readonly":"","name":"planUseTime","placeholder":"请选择","required":"","input-align":"right","error-message-align":"right","min-date":_vm.minPlanUseTime,"max-date":_vm.maxPlanUseTime,"rules":[
          {
            required: true,
            message: "请选择计划投用时间",
            trigger: 'onBlur'
          },
          _vm.planUseTimeRule
        ]},model:{value:(_vm.form.planUseTime),callback:function ($$v) {_vm.$set(_vm.form, "planUseTime", $$v)},expression:"form.planUseTime"}}):_vm._e(),(!_vm.form.changeType)?_c('van-field',{key:"stopRiskScheme",attrs:{"rows":"3","required":"","autosize":"","label":"停用风险控制方案","type":"textarea","maxlength":"500","placeholder":"请输入停用风险控制方案","show-word-limit":"","rules":[
          {
            required: true,
            message: '请输入停用风险控制方案',
            trigger: 'onBlur'
          }
        ]},model:{value:(_vm.form.stopRiskScheme),callback:function ($$v) {_vm.$set(_vm.form, "stopRiskScheme", $$v)},expression:"form.stopRiskScheme"}}):_vm._e(),_c('van-field',{attrs:{"label":"申请机构"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_vm._v(" "+_vm._s(_vm.form.orgName)+" ")]},proxy:true}],null,false,4069828323)}),_c('van-field',{attrs:{"label":"申请人"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_vm._v(" "+_vm._s(_vm.form.applyUserName)+" ")]},proxy:true}],null,false,1757791836)})],1):_vm._e()],1),_c('BottomFixedBox',[_c('van-button',{staticClass:"confirm-btn",attrs:{"round":"","size":"small","type":"info"},on:{"click":function($event){return _vm.onSave()}}},[_vm._v("保 存")]),_c('van-button',{staticClass:"confirm-btn",attrs:{"round":"","size":"small","type":"info"},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v("提 交")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }