var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"flex-grow":"1"}},[(_vm.hasPermission)?_c('van-button',{staticStyle:{"width":"100%"},attrs:{"round":"","size":"small","type":"info"},on:{"click":_vm.onCountersign,"closed":_vm.onClosed}},[_vm._v(_vm._s(_vm.bizFlowName))]):_vm._e(),_c('van-dialog',{staticClass:"switching-detail-dialog",class:{ loading: _vm.loading },attrs:{"title":_vm.bizFlowName,"show-cancel-button":"","disabled":_vm.loading,"before-close":_vm.beforeClose},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('van-form',{ref:"form",staticClass:"content-box",attrs:{"validate-trigger":"onSubmit"}},[_c('TimePickerFormItem',{attrs:{"readonly":"","input-align":"right","is-link":"","label":("实际" + _vm.bizFlowName + "时间"),"placeholder":("请选择实际" + _vm.bizFlowName + "时间"),"required":"","rules":[
          {
            required: true,
            message: ("请选择实际" + _vm.bizFlowName + "时间"),
            trigger: 'onBlur'
          }
        ]},model:{value:(_vm.form.actualUseTime),callback:function ($$v) {_vm.$set(_vm.form, "actualUseTime", $$v)},expression:"form.actualUseTime"}}),_c('SwitchingTextarea',{attrs:{"rows":"3","required":"","autosize":{ maxHeight: 170 },"label":"执行状况","type":"textarea","maxlength":"2000","placeholder":"请输入执行状况","show-word-limit":"","rules":[
          {
            required: true,
            message: "请输入执行状况",
            trigger: 'onBlur'
          }
        ]},model:{value:(_vm.form.approveContent),callback:function ($$v) {_vm.$set(_vm.form, "approveContent", $$v)},expression:"form.approveContent"}}),_c('SwitchingFileUploader',{attrs:{"accept":_vm.FILE_ACCEPT_TYPES.FILE,"max-size":1024 * 1024 * 10,"tip":"文件大小不超过 10M"},model:{value:(_vm.form.attachments),callback:function ($$v) {_vm.$set(_vm.form, "attachments", $$v)},expression:"form.attachments"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }