<template>
  <div class="switching-list">
    <slot name="header" />
    <LoadListView
      v-model="loadStatus"
      :list="list"
      :total="total"
      v-bind="$attrs"
      @load="getList"
    >
      <div
        v-for="(item, idx) in list"
        :key="idx"
        class="switching-item"
        @click="gotoDetails(item)"
      >
        <div class="switching-item-header">
          {{ item.chainChangeVo.workNo }}-{{
            item.chainAccountVo.chainCircuitName
          }}
        </div>

        <div class="switching-item-body">
          <div class="switching-item-row">
            <span class="switching-item-text">
              <van-icon class-prefix="iconfont" name="guankongcengji" />
              {{ stopStatusNameMap.get(item.chainChangeVo.stopStatus) || "--" }}
            </span>
            <span class="switching-item-text">
              <van-icon class-prefix="iconfont" name="zuoyexuke" />
              {{ item.chainAccountVo.chainLevelName }}
            </span>
          </div>
          <div class="switching-item-row">
            <span class="switching-item-text">
              <van-icon class-prefix="iconfont" name="bumen" />
              {{ item.chainChangeVo.departmentName }}
            </span>
            <span class="switching-item-text">
              <van-icon class-prefix="iconfont" name="yonghu1" />
              {{ item.chainChangeVo.applyUserName }}
            </span>
          </div>
          <div class="switching-item-row">
            <span class="switching-item-text">
              <van-icon class-prefix="iconfont" name="riqi" />
              {{ item.chainChangeVo.planStopTime }} 至
              {{ item.chainChangeVo.planUseTime }}
            </span>
          </div>
          <div
            v-if="StatusIcon[item.chainChangeVo.status]"
            class="switching-item-status"
          >
            <van-icon
              :style="{ color: StatusIcon[item.chainChangeVo.status].color }"
              :name="StatusIcon[item.chainChangeVo.status].icon"
              class-prefix="iconfont"
              class="switching-item-icon"
            />
          </div>
        </div>
      </div>
    </LoadListView>
  </div>
</template>

<script>
import { DECOMMISSIONS, StatusIcon } from "../utils";
export default {
  props: {
    api: {
      type: Function,
      default: () => Promise.resolve({ total: 0, list: [] })
    }
  },
  data() {
    return {
      loadStatus: "loading",
      query: {
        size: 10,
        page: 1
      },
      list: [],
      total: 0,
      error: false
    };
  },
  computed: {
    stopStatusNameMap() {
      const map = new Map(DECOMMISSIONS.map(i => [i.value, i.label]));
      return map;
    },
    StatusIcon: () => StatusIcon
  },
  mounted() {
    this.getList();
  },
  methods: {
    gotoDetails(item) {
      this.$router.push({ path: "/switching/" + item.chainChangeVo.id });
    },
    getList(refresh = false) {
      if (refresh) {
        this.query.page = 1;
        this.list = [];
        this.total = 0;
        this.loadStatus = "loading";
      }

      //待处理没有分页
      this.api(this.query)
        .then(({ list = [], total = 0 }) => {
          this.list.push(...list);
          this.query.page++;
          this.total = total;
          this.loadStatus = "success";
        })
        .catch(error => {
          console.log(error);
          this.loadStatus = "error";
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.switching {
  &-item {
    padding: 10px 16px;
    background-color: #fff;
    line-height: 17px;
    font-size: 12px;
    font-weight: 400;
    color: #a0a2a6;
    line-height: 17px;
    border-bottom: 8px solid #eceef2;
  }

  &-item-body {
    position: relative;
  }

  &-item-header {
    font-size: 14px;
    font-weight: bold;
    color: #2e2e4d;
    line-height: 20px;
  }

  &-item-row {
    margin-top: 5px;
  }

  &-item-text + &-item-text {
    margin-left: 20px;
  }

  &-item-status {
    width: 44px;
    height: 40px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &-item-icon {
    font-size: 44px;
  }
}
</style>
