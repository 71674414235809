<template>
  <div style="flex-grow: 1;">
    <van-button
      v-if="hasPermission"
      style="width: 100%;"
      round
      size="small"
      type="info"
      @click="onCountersign"
      >{{ bizFlowName }}</van-button
    >

    <van-dialog
      v-model="visible"
      class="switching-detail-dialog"
      :class="{ loading }"
      :title="bizFlowName"
      show-cancel-button
      :disabled="loading"
      :before-close="beforeClose"
      @closed="onClosed"
    >
      <div v-if="data.flowIsDelay === 1" class="delay-info">
        <div class="delay-info-box">
          <div class="delay-info-item">
            <div class="delay-info-title">计划投用时间：</div>
            <div class="delay-info-text">{{ delayInfo.planUseTime }}</div>
          </div>

          <div class="delay-info-item">
            <div class="delay-info-title">延期原因：</div>
            <div class="delay-info-text">{{ delayInfo.reason }}</div>
          </div>

          <div class="delay-info-item is-flex">
            <div class="delay-info-title">停用性质：</div>
            <div class="delay-info-text">{{ delayInfo.stopStatusName }}</div>
          </div>
        </div>
      </div>
      <van-form ref="form" class="content-box" validate-trigger="onSubmit">
        <!-- 决定 -->
        <van-field
          :label="`${bizFlowName}决定`"
          required
          :rules="[
            {
              required: true,
              message: `请选择${bizFlowName}决定`,
              trigger: 'onBlur'
            }
          ]"
        >
          <template #input>
            <van-radio-group
              v-model="form.approveStatus"
              direction="horizontal"
            >
              <van-radio :name="1">通过</van-radio>
              <van-radio :name="2">驳回</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <!-- 意见 -->
        <SwitchingTextarea
          v-model="form.approveContent"
          rows="3"
          required
          :autosize="{ maxHeight: 170 }"
          :label="`${bizFlowName}意见`"
          type="textarea"
          maxlength="2000"
          :placeholder="`请输入${bizFlowName}意见`"
          show-word-limit
          :rules="[
            {
              required: true,
              message: `请输入${bizFlowName}意见`,
              trigger: 'onBlur'
            }
          ]"
        />
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import { approveChange, getChainChangeDelay } from "../../api";
import SwitchingTextarea from "../form/SwitchingTextarea.vue";

const defaultForm = {
  approveStatus: null,
  approveContent: ""
};

export default {
  components: {
    SwitchingTextarea
  },
  props: {
    hasPermission: {
      type: Boolean,
      default: false
    },
    bizFlowName: {
      type: String,
      default: ""
    },
    baseInfo: {
      type: Object,
      default: () => ({})
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      delayInfo: {},
      visible: false,
      loading: false,
      form: { ...defaultForm }
    };
  },
  methods: {
    onCountersign() {
      this.visible = true;
      this.form = { ...defaultForm };
      this.$nextTick(() => {
        this.$refs.form.resetValidation();
      });

      if (this.data.flowIsDelay) {
        getChainChangeDelay({ changeId: this.$route.params.id }).then(res => {
          this.delayInfo = res;
        });
      }
    },
    beforeClose(action, done) {
      if (action === "confirm") {
        this.$refs.form
          .validate()
          .then(() => {
            this.loading = true;
            approveChange({ ...this.baseInfo, ...this.form })
              .then(() => {
                this.$toast.success("操作成功");
                this.$emit("on-success");
                this.loading = false;
                done();
              })
              .catch(() => {
                this.loading = false;
                this.$toast.fail("操作失败, 请重试");
                done(false);
              });
          })
          .catch(() => {
            done(false);
          });
      } else {
        done();
      }
    },
    onClosed() {
      this.loading = false;
      this.form = { ...defaultForm };
    }
  }
};
</script>

<style lang="scss" scoped>
.switching-detail-dialog {
  top: calc(45% + 58px);
}
.delay-info {
  padding: 10px;

  &-box {
    background: #f3f5f8;
    padding: 12px 10px;
    border-radius: 8px;
  }

  &-item {
    max-height: 100px;
    overflow: auto;
    &.is-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &-item + &-item {
    margin-top: 12px;
  }

  &-title {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #5d5f63;
    line-height: 20px;
    margin-bottom: 4px;
  }

  &-text {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #242f3b;
    line-height: 20px;
  }
}
</style>
