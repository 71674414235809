<template>
  <div class="switching-list has-nav-bar">
    <van-nav-bar
      :border="false"
      class="switching-nav"
      fixed
      left-arrow
      @click-left="$router.back()"
    >
      <div slot="title" class="nav-title">我的联锁投切</div>
      <div slot="right" class="nav-right">
        <van-icon
          v-if="btnAuth.add"
          size="24"
          name="iconfont-xinzengfengxian"
          class-prefix="iconfont"
          class="iconfont-xinzengfengxian"
          @click="onClickRight()"
        />
      </div>
    </van-nav-bar>

    <van-tabs v-model="activeName">
      <van-tab title="待我处理" :name="TODO">
        <SwitchingList v-if="activeName === TODO" :api="getSwitchingListTodo" />
      </van-tab>
      <van-tab title="与我相关" :name="MINE">
        <SwitchingList
          v-if="activeName === MINE"
          ref="mine-list"
          :show-total-view="true"
          :api="getSwitchingListMine"
        >
          <template slot="header">
            <SwitchingSearch :data="searchData" @on-search="onSearchMineList" />
          </template>
        </SwitchingList>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SwitchingList from "./components/SwitchingList.vue";
import SwitchingSearch from "./components/SwitchingSearch.vue";

import { getSwitchingListTodo, getSwitchingListMine } from "./api";

const TODO = "TODO";
const MINE = "MINE";

export default {
  components: {
    SwitchingList,
    SwitchingSearch
  },
  data() {
    return {
      activeName: TODO,
      searchData: {
        keyWord: "",
        department: "",
        chainLevel: "",
        changeType: "",
        stopStatus: "",
        status: ""
      }
    };
  },
  computed: {
    ...mapState({
      btnAuth: state => state.menu.btnAuth
    }),
    TODO: () => TODO,
    MINE: () => MINE
  },
  methods: {
    onClickRight() {
      this.$router.push({ path: "/switching/add" });
    },
    getSwitchingListTodo(query = {}) {
      return getSwitchingListTodo(query);
    },
    getSwitchingListMine(query = {}) {
      return getSwitchingListMine({ ...this.searchData, ...query });
    },
    onSearchMineList() {
      this.$refs["mine-list"] && this.$refs["mine-list"].getList(true);
    }
  }
};
</script>
