<template>
  <div v-if="data.applyUserId === userInfo.id" class="re-submit">
    <van-button
      class="re-submit-btn"
      round
      size="small"
      type="info"
      @click="onEdit"
      >编 辑</van-button
    >
    <van-button
      class="re-submit-btn"
      round
      size="small"
      type="info"
      @click="onCountersign"
      >取消申请</van-button
    >
  </div>
</template>

<script>
import { approveChange } from "../../api";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    hasPermission: {
      type: Boolean,
      default: false
    },
    bizFlowName: {
      type: String,
      default: ""
    },
    baseInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: { ...defaultForm }
    };
  },
  methods: {
    onEdit() {
      this.$router.push({
        name: "switching-edit",
        params: { id: this.$route.params.id }
      });
    },
    async onCountersign() {
      await this.$dialog.confirm({
        title: "提示",
        message: "确定要取消申请吗？"
      });
      approveChange({ ...this.baseInfo, approveStatus: 3 })
        .then(() => {
          this.$toast.success("操作成功");
          this.$emit("on-success");
        })
        .catch(() => {
          this.$toast.fail("操作失败, 请重试");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.re-submit {
  flex-grow: 1;
  display: flex;

  &-btn {
    width: 50%;
    flex-grow: 1;
  }
}
</style>
