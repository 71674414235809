<template>
  <div class="stop-check">
    <DelaySubmit
      v-if="data.applyUserId === userInfo.id"
      class="stop-check-btn"
      :base-info="baseInfo"
      :actual-stop-time="data.actualStopTime"
      :change-type="data.changeType"
      @on-success="$emit('on-success')"
    />

    <van-button
      v-if="hasPermission"
      class="stop-check-btn"
      round
      size="small"
      type="info"
      @click="onSubmit()"
      >{{ bizFlowName }}</van-button
    >
  </div>
</template>

<script>
import { approveChange } from "../../api";
import DelaySubmit from "./DelaySubmit.vue";

export default {
  components: {
    DelaySubmit
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    hasPermission: {
      type: Boolean,
      default: false
    },
    bizFlowName: {
      type: String,
      default: ""
    },
    baseInfo: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    async onSubmit() {
      await this.$dialog.confirm({ title: "提示", message: "确定要提交吗？" });
      approveChange({ ...this.baseInfo, approveStatus: 1 })
        .then(() => {
          this.$toast.success("操作成功");
          this.$emit("on-success");
        })
        .catch(() => {
          this.$toast.fail("操作失败, 请重试");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.stop-check {
  flex-grow: 1;
  display: flex;

  &-btn {
    width: 50%;
    flex-grow: 1;
  }

  &-btn + &-btn {
    margin-left: 13px;
  }
}
</style>
