<template>
  <div>
    <van-field
      is-link
      readonly
      :value="value"
      name="chainCircuitBit"
      label="联锁回路位号"
      placeholder="请选择"
      required
      input-align="right"
      error-message-align="right"
      :rules="[
        {
          required: true,
          message: '请选择联锁回路位号',
          trigger: 'onChange'
        }
      ]"
      @click="visible = true"
    />

    <multiple-select
      :choosed-val="tags"
      :data="labelList"
      title="联锁回路位号"
      :visible="visible"
      label-key="label"
      value-key="value"
      @confirm="onConfirm"
      @close="onClose"
    />
  </div>
</template>

<script>
import { getCircuitBitListSelect } from "../api";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    value: String
  },
  data() {
    return {
      visible: false,
      list: []
    };
  },
  computed: {
    tags: {
      get() {
        if (!this.value) return [];
        return this.value.split(",").map(value => ({ value }));
      },
      set(val) {
        this.data.chainCircuitBit = val ? val.join(",") : "";
      }
    },
    labelList() {
      return this.list.map(i => ({
        value: i.chainCircuitBit,
        label: i.chainCircuitBit,
        disabled: i.chainCircuitBitStatus === 1
      }));
    }
  },
  watch: {
    "data.chainAccountId"() {
      this.getCircuitBitListSelect();
      this.data.chainCircuitBit = "";
      this.data.chainCircuitDescription = "";
    }
  },
  mounted() {
    if (this.data.chainAccountId) {
      this.getCircuitBitListSelect();
    }
  },
  methods: {
    onConfirm(val) {
      this.tags = val ? val.map(i => i.value) : [];
      this.visible = false;
    },
    onClose() {
      this.visible = false;
    },
    getCircuitBitListSelect() {
      if (!this.data.chainAccountId) {
        this.list = [];
        return;
      }

      getCircuitBitListSelect(this.data.chainAccountId).then(res => {
        this.list = res || [];
      });
    }
  }
};
</script>
