var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"flex-grow":"1"}},[(_vm.hasPermission)?_c('van-button',{staticStyle:{"width":"100%"},attrs:{"round":"","size":"small","type":"info"},on:{"click":_vm.onCountersign,"closed":_vm.onClosed}},[_vm._v(_vm._s(_vm.bizFlowName))]):_vm._e(),_c('van-dialog',{staticClass:"switching-detail-dialog",class:{ loading: _vm.loading },attrs:{"title":_vm.bizFlowName,"show-cancel-button":"","disabled":_vm.loading,"before-close":_vm.beforeClose},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('van-form',{ref:"form",staticClass:"content-box",attrs:{"validate-trigger":"onSubmit"}},[_c('van-field',{attrs:{"label":"验收决定","required":"","rules":[
          {
            required: true,
            message: "请选择验收决定",
            trigger: 'onBlur'
          }
        ]},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('van-radio-group',{attrs:{"direction":"horizontal"},model:{value:(_vm.form.approveStatus),callback:function ($$v) {_vm.$set(_vm.form, "approveStatus", $$v)},expression:"form.approveStatus"}},[_c('van-radio',{attrs:{"name":1}},[_vm._v("通过")]),_c('van-radio',{attrs:{"name":2}},[_vm._v("驳回")])],1)]},proxy:true}])}),_c('SwitchingTextarea',{attrs:{"rows":"3","required":"","autosize":{ maxHeight: 170 },"label":"验收意见","type":"textarea","maxlength":"2000","placeholder":"请输入验收意见","show-word-limit":"","rules":[
          {
            required: true,
            message: "请输入验收意见",
            trigger: 'onBlur'
          }
        ]},model:{value:(_vm.form.approveContent),callback:function ($$v) {_vm.$set(_vm.form, "approveContent", $$v)},expression:"form.approveContent"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }