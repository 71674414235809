<template>
  <div>
    <van-field
      :value="data.departmentName"
      name="departmentName"
      label="所属部门"
      is-link
      required
      clearable
      placeholder="请选择"
      readonly
      input-align="right"
      error-message-align="right"
      :rules="[
        {
          required: true,
          message: '请选择所属部门',
          trigger: 'onChange'
        }
      ]"
      @click="visible = true"
    />

    <cascade-department
      v-model="data.department"
      :visible="visible"
      disabled-key="--"
      @close="visible = false"
      @confirm="handlerSelectDepartment"
    />
  </div>
</template>

<script>
import { treeForEach } from "@/utils/index";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    value: String
  },
  data() {
    return {
      visible: false
    };
  },
  methods: {
    handlerSelectDepartment(cur, { tree, include }) {
      let parent = null;
      treeForEach(tree, node => {
        if (node.id === cur._parendId) parent = node;
      });
      this.data.departmentName = cur.label;
      this.data.departmentNames = parent
        ? parent.label + "-" + cur.label
        : cur.label;
      this.$set(this.data, "subDepartment", include);
    }
  }
};
</script>
