<template>
  <div>
    <van-field
      is-link
      readonly
      :value="data.chainAccountName"
      name="chainAccountName"
      label="联锁回路名称"
      placeholder="请选择"
      required
      input-align="right"
      error-message-align="right"
      :rules="[
        {
          required: true,
          message: ' ',
          trigger: 'onChange'
        }
      ]"
      @click="visible = true"
    />

    <select-popup
      v-model="data.chainAccountId"
      :data="list"
      title="联锁回路名称"
      :visible="visible"
      label-key="label"
      value-key="value"
      @close="visible = false"
      @confirm="onConfirm"
    />
  </div>
</template>

<script>
import { getSelectInterlockLedger, getInterLockLedgerDetail } from "../api";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    value: String
  },
  data() {
    return {
      visible: false,
      list: []
    };
  },
  watch: {
    "data.department"() {
      this.getSelectInterlockLedger();
      this.data.chainAccountId = "";
      this.data.chainAccountName = "";
      this.data.chainLevelName = "";
    }
  },
  mounted() {
    this.getSelectInterlockLedger();
  },
  methods: {
    onConfirm(row) {
      this.data.chainAccountName = row.label;

      getInterLockLedgerDetail(row.value).then(res => {
        this.data.chainLevelName = res.chainAccountVo.chainLevelName;
        this.data.chainCircuitDescription = res.actionVoList
          .map(v => v.chainActionContent)
          .join(";");
      });
    },
    getSelectInterlockLedger() {
      getSelectInterlockLedger({ department: this.data.department }).then(
        res => {
          this.list = res || [];
        }
      );
    }
  }
};
</script>
