<template>
  <div class="switching-add has-nav-bar">
    <van-nav-bar
      :border="false"
      class="switching-nav"
      left-arrow
      fixed
      @click-left="$router.back()"
    >
      <div slot="title" class="nav-title">联锁投切({{ form.statusName }})</div>

      <div v-if="steps.length" slot="right" class="nav-right">
        <van-icon
          class-prefix="iconfont"
          name="liucheng"
          size="24"
          @click="onProgess"
        />
      </div>
    </van-nav-bar>

    <LoadView v-model="loadStatus" style="width: 100%;">
      <van-form ref="form" class="content-box" validate-trigger="onSubmit">
        <!-- 所属部门 -->
        <van-field label="所属部门">
          <template #input>
            {{ chainCircuit.departmentName }}
          </template>
        </van-field>
        <!-- 联锁回路名称 -->
        <van-field label="联锁回路名称">
          <template #input>
            {{ chainCircuit.chainCircuitName }}
          </template>
        </van-field>
        <!-- 联锁回路位号 -->
        <van-field label="联锁回路位号">
          <template #input>
            {{ form.chainCircuitBit }}
          </template>
        </van-field>
        <!-- 联锁等级 -->
        <van-field label="联锁等级">
          <template #input>
            {{ chainCircuit.chainLevelName }}
          </template>
        </van-field>

        <!-- 联锁回路功能描述 -->
        <van-field
          :value="form.chainCircuitDescription"
          readonly
          rows="3"
          autosize
          label="联锁回路功能描述"
          type="textarea"
        />

        <!-- 上传附件	 -->
        <SwitchingFileUploader
          v-model="form.attachments"
          unable-upload
          accept="application/pdf,image/png,image/jpeg"
        />

        <!-- 投切类型 -->
        <van-field label="投切类型">
          <template #input>
            <van-radio-group
              :value="form.changeType"
              readonly
              direction="horizontal"
            >
              <van-radio
                v-for="item in LOCK_TYPE"
                :key="item.value"
                :name="item.value"
                style="padding: 2px 0;"
                >{{ item.label }}</van-radio
              >
            </van-radio-group>
          </template>
        </van-field>

        <!-- 停用性质 -->
        <van-field v-if="!form.changeType" key="stopStatus" label="停用性质">
          <template #input>
            <van-radio-group
              :value="form.stopStatus"
              readonly
              direction="horizontal"
            >
              <van-radio
                v-for="item in DECOMMISSIONS"
                :key="item.value"
                :name="item.value"
                style="padding: 2px 0;"
                >{{ item.label }}</van-radio
              >
            </van-radio-group>
          </template>
        </van-field>

        <!-- 投用/停用原因 -->
        <van-field
          key="reason"
          :value="form.reason"
          readonly
          rows="3"
          autosize
          :label="`${form.changeType ? '投用' : '停用'}原因`"
          type="textarea"
        />

        <!-- 计划停用时间 -->
        <van-field label="计划停用时间">
          <template #input>
            {{ form.planStopTime }}
          </template>
        </van-field>

        <!-- 计划投用时间 -->
        <van-field label="计划投用时间">
          <template #input>
            {{ form.planUseTime }}
          </template>
        </van-field>

        <!-- 停用风险控制方案 -->
        <van-field
          v-if="!form.changeType"
          key="stopRiskScheme"
          :value="form.stopRiskScheme"
          rows="3"
          autosize
          readonly
          label="停用风险控制方案"
          type="textarea"
        />

        <van-field label="申请机构">
          <template #input>
            {{ form.orgName }}
          </template>
        </van-field>

        <van-field label="申请人">
          <template #input>
            {{ form.applyUserName }}
          </template>
        </van-field>
      </van-form>
    </LoadView>

    <BottomFixedBox>
      <component
        :is="StatusBtns"
        :data="form"
        :base-info="baseInfo"
        :biz-flow-name="bizFlowName"
        :has-permission="buttonObj.hasPermission"
        :change-type="form.changeType"
        @on-success="onSuccess"
      />
    </BottomFixedBox>
  </div>
</template>

<script>
import LoadView from "@/components/loadview/LoadView";
import BottomFixedBox from "@/components/general/BottomFixedBox";
import SwitchingFileUploader from "./components/form/SwitchingFileUploader.vue";

import { LOAD_STATUS } from "@/components/loadview/utils";
import { LOCK_TYPE, DECOMMISSIONS, StatusBtns } from "./utils";

import { getInterlockChangeDetail, getListChainFlowNode } from "./api";

export default {
  components: {
    LoadView,
    BottomFixedBox,
    SwitchingFileUploader
  },
  data() {
    return {
      loadStatus: LOAD_STATUS.LOADING,
      form: {},
      chainCircuit: {},
      steps: []
    };
  },
  computed: {
    LOCK_TYPE: () => LOCK_TYPE,
    DECOMMISSIONS: () => DECOMMISSIONS,
    id() {
      return this.$route.params.id;
    },
    buttonObj() {
      return this.steps.find(v => v.bizFlowNodeStatus === 1) || {};
    },
    bizFlowName() {
      return this.buttonObj.bizFlowName || "";
    },
    StatusBtns() {
      return StatusBtns[this.form.status];
    },
    baseInfo() {
      return {
        stopStatus: this.form.stopStatus,
        chainChangeId: this.form.id,
        operationUserId: this.userInfo.id,
        operationType: this.buttonObj.bizFlowNode,
        bizFlowNode: this.buttonObj.bizFlowNode
      };
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      Promise.all([
        getInterlockChangeDetail(this.id),
        getListChainFlowNode({
          chainChangId: this.id,
          concurrentUserId: this.userInfo.id
        })
      ])
        .then(([res, steps]) => {
          this.steps = steps || [];
          if (!res) {
            this.loadStatus = LOAD_STATUS.EMPTY;
            return;
          }
          this.form = {
            ...res.chainChangeVo
          };
          this.chainCircuit = res.chainAccountVo;
          this.loadStatus = LOAD_STATUS.SUCCESS;
        })
        .catch(err => {
          console.log("err", err);
          this.loadStatus = LOAD_STATUS.ERROR;
        });
    },
    onSuccess() {
      this.getData();
    },
    onProgess() {
      this.$router.push({
        name: "switching-progress",
        parmas: { id: this.id }
      });
    }
  }
};
</script>

<style lang="scss">
// 覆盖弹窗样式
.switching-detail-dialog {
  .van-field.van-cell::after {
    display: none;
  }
}
</style>
