import ToBeSubmited from "./components/StatusBtns/ToBeSubmited.vue";
import Countersigning from "./components/StatusBtns/Countersigning.vue";
import WaitStopAssign from "./components/StatusBtns/WaitStopAssign.vue";
import WaitStop from "./components/StatusBtns/WaitStop.vue";
import StopWaitCheck from "./components/StatusBtns/StopWaitCheck.vue";
import StopCheck from "./components/StatusBtns/StopCheck.vue";
import PutSubmit from "./components/StatusBtns/PutSubmit.vue";
import WaitReSubmit from "./components/StatusBtns/WaitReSubmit.vue";

export const DRAFT = -1; // 待提交
export const SINGING = 1; // 会签中
export const APPROVING = 2; // 审批中
export const WAIT_STOP_ASSIGN = 3; // 待停用指派
export const WAIT_STOP = 4; // 待停用
export const STOP_WAIT_CHECK = 5; // 停用待验收
export const STOP_CHECK = 6; // 已停用验收
export const PUT_SUBMIT = 7; // 已投用申请
export const WAIT_USE = 8; // 待投用
export const USE_WAIT_CHECK = 9; // 投用待验收
export const WAIT_RE_SUBMIT = 10; // 待重新提交
export const CANCEL = 11; // 已取消
export const FINISHED = 12; // 已完成

export const StatusBtns = {
  [DRAFT]: ToBeSubmited,
  [SINGING]: Countersigning,
  [APPROVING]: Countersigning,
  [WAIT_STOP_ASSIGN]: WaitStopAssign,
  [WAIT_STOP]: WaitStop,
  [STOP_WAIT_CHECK]: StopWaitCheck,
  [STOP_CHECK]: StopCheck,
  [PUT_SUBMIT]: PutSubmit,
  [WAIT_USE]: WaitStop,
  [USE_WAIT_CHECK]: StopWaitCheck,
  [WAIT_RE_SUBMIT]: WaitReSubmit
};

export const StatusIcon = {
  [DRAFT]: { icon: "daitijiao1", color: "#F68446" },
  [SINGING]: { icon: "huiqianzhong", color: "#3683F4" },
  [APPROVING]: { icon: "shenpizhong", color: "#3683F4" },
  [WAIT_STOP_ASSIGN]: { icon: "daitingyongzhipai", color: "#3683F4" },
  [WAIT_STOP]: { icon: "daitingyong", color: "#3683F4" },
  [STOP_WAIT_CHECK]: { icon: "tingyongdaiyanshou", color: "#3683F4" },
  [STOP_CHECK]: { icon: "yitingyongyanshou", color: "#3683F4" },
  [PUT_SUBMIT]: { icon: "yitouyongshenqing", color: "#3683F4" },
  [WAIT_USE]: { icon: "daitouyong", color: "#3683F4" },
  [USE_WAIT_CHECK]: { icon: "touyongdaiyanshou", color: "#3683F4" },
  [WAIT_RE_SUBMIT]: { icon: "daizhongxintijiao", color: "#F68446" },
  [CANCEL]: { icon: "icoyiquxiao", color: "#9B9B9B" },
  [FINISHED]: { icon: "yiwancheng", color: "#8EB433" }
};

export const LOCK_TYPE = [
  {
    label: "停用",
    value: 0
  },
  {
    label: "直接投用",
    value: 3
  }
];

export const DECOMMISSIONS = [
  {
    label: "临时解除",
    value: 0
  },
  {
    label: "长期停用",
    value: 1
  },
  {
    label: "永久摘除",
    value: 2
  }
];
