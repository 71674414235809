<template>
  <div :class="className">
    <van-nav-bar
      title="联锁投切"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <van-list :class="`${className}__list`">
      <van-cell
        v-for="(item, idx) in troubleConf"
        :key="idx"
        is-link
        clickable
        :title="item.title"
        :class="`${className}__list-item`"
        :to="{ name: item.routerName, query: item.routerQuery || {} }"
        :border="idx !== troubleConf.length - 1"
      >
        <template #title>
          <van-icon
            :name="item.icon"
            :color="item.iconColor"
            class-prefix="iconfont"
            class="van-cell__left-icon"
          />
          <span>{{ item.title }}</span>
        </template>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
export default {
  name: "TroubleDispatch",
  data() {
    return {
      className: "interlock-switching",
      troubleConf: [
        {
          icon: "wodeliansuotouqie",
          iconColor: "#BF66CE",
          title: "我的联锁投切",
          routerName: "switching-mine"
        },
        {
          icon: "liansuotouqiechaxun",
          iconColor: "#1EB6CD",
          title: "联锁投切查询",
          routerName: "switching-query"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.interlock-switching {
  &__list {
    .van-cell::after {
      border-bottom: 1px solid #ebebeb;
    }
  }
  &__list-item {
    padding: 15px 20px;
    .van-cell__left-icon {
      font-size: 24px;
      margin-right: 8px;
      vertical-align: -4px;
    }
    span {
      color: #333333;
      font-size: 15px;
    }
  }
}
</style>
