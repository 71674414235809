<template>
  <div style="flex-grow: 1;">
    <van-button
      v-if="hasPermission"
      style="width: 100%;"
      round
      size="small"
      type="info"
      @click="onCountersign"
      @closed="onClosed"
      >{{ bizFlowName }}</van-button
    >

    <van-dialog
      v-model="visible"
      class="switching-detail-dialog"
      :class="{ loading }"
      :title="bizFlowName"
      show-cancel-button
      :disabled="loading"
      :before-close="beforeClose"
    >
      <van-form ref="form" class="content-box" validate-trigger="onSubmit">
        <!-- 决定 -->
        <van-field
          :label="`验收决定`"
          required
          :rules="[
            {
              required: true,
              message: `请选择验收决定`,
              trigger: 'onBlur'
            }
          ]"
        >
          <template #input>
            <van-radio-group
              v-model="form.approveStatus"
              direction="horizontal"
            >
              <van-radio :name="1">通过</van-radio>
              <van-radio :name="2">驳回</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <!-- 意见 -->
        <SwitchingTextarea
          v-model="form.approveContent"
          rows="3"
          required
          :autosize="{ maxHeight: 170 }"
          :label="`验收意见`"
          type="textarea"
          maxlength="2000"
          :placeholder="`请输入验收意见`"
          show-word-limit
          :rules="[
            {
              required: true,
              message: `请输入验收意见`,
              trigger: 'onBlur'
            }
          ]"
        />
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import SwitchingTextarea from "../form/SwitchingTextarea.vue";
import { approveChange } from "../../api";

const defaultForm = {
  approveStatus: null,
  approveContent: ""
};

export default {
  components: {
    SwitchingTextarea
  },
  props: {
    hasPermission: {
      type: Boolean,
      default: false
    },
    bizFlowName: {
      type: String,
      default: ""
    },
    baseInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      form: { ...defaultForm }
    };
  },
  methods: {
    onCountersign() {
      this.visible = true;
      this.form = { ...defaultForm };
      this.$nextTick(() => {
        this.$refs.form.resetValidation();
      });
    },
    beforeClose(action, done) {
      if (action === "confirm") {
        this.$refs.form
          .validate()
          .then(() => {
            this.loading = true;
            approveChange({ ...this.baseInfo, ...this.form })
              .then(() => {
                this.$toast.success("操作成功");
                this.$emit("on-success");
                this.loading = false;
                done();
              })
              .catch(() => {
                this.loading = false;
                this.$toast.fail("操作失败, 请重试");
                done(false);
              });
          })
          .catch(() => {
            done(false);
          });
      } else {
        done();
      }
    },
    onClosed() {
      this.loading = false;
      this.form = { ...defaultForm };
    }
  }
};
</script>
