<template>
  <div class="switching-add has-nav-bar">
    <van-nav-bar
      :border="false"
      class="switching-nav"
      left-arrow
      fixed
      @click-left="$router.back()"
    >
      <div slot="title" class="nav-title">
        流程
      </div>
    </van-nav-bar>

    <van-tabs>
      <van-tab title="流程图">
        <SwitchingProgress :steps="steps" />
      </van-tab>
      <van-tab title="操作记录">
        <SwitchingRecord :records="records" />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import SwitchingProgress from "./components/SwitchingProgress.vue";
import SwitchingRecord from "./components/SwitchingRecord.vue";
import { getListChainFlowNode, getChangeRecords } from "./api";
export default {
  components: {
    SwitchingProgress,
    SwitchingRecord
  },
  data() {
    return {
      steps: [],
      records: []
    };
  },
  created() {
    this.getSteps();
    this.getRecords();
  },
  methods: {
    getSteps() {
      getListChainFlowNode({
        chainChangId: this.$route.params.id,
        concurrentUserId: this.userInfo.id
      }).then(res => {
        this.steps = res || [];
      });
    },
    getRecords() {
      getChangeRecords({ chainChangeId: this.$route.params.id }).then(res => {
        this.records = res || [];
      });
    }
  }
};
</script>
