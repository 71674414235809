<template>
  <div>
    <van-field :value="value" v-bind="$attrs" @click="onSelect" />

    <van-popup v-model="visible" position="bottom" get-container="body">
      <van-datetime-picker
        v-model="time"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="onCancel"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  props: {
    value: {
      type: String,
      default: ""
    },
    minDate: Date,
    maxDate: Date
  },
  data() {
    return {
      visible: false,
      time: ""
    };
  },
  methods: {
    onSelect() {
      this.time = this.value ? new Date(this.value) : new Date();
      this.$nextTick(() => {
        this.visible = true;
      });
    },
    onConfirm() {
      this.visible = false;
      const time = this.time
        ? dayjs(this.time).format("YYYY-MM-DD HH:mm:ss")
        : "";
      this.$emit("input", time);
    },
    onCancel() {
      this.visible = false;
      this.$emit("input", "");
    }
  }
};
</script>
