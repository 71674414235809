<template>
  <div style="flex-grow: 1;">
    <van-button
      v-if="hasPermission"
      style="width: 100%;"
      round
      size="small"
      type="info"
      @click="onCountersign"
      @closed="onClosed"
      >{{ bizFlowName }}</van-button
    >

    <van-dialog
      v-model="visible"
      class="switching-detail-dialog"
      :class="{ loading }"
      :title="bizFlowName"
      show-cancel-button
      :disabled="loading"
      :before-close="beforeClose"
    >
      <van-form ref="form" class="content-box" validate-trigger="onSubmit">
        <!-- 时间 -->
        <TimePickerFormItem
          v-model="form.actualUseTime"
          readonly
          input-align="right"
          is-link
          :label="`实际${bizFlowName}时间`"
          :placeholder="`请选择实际${bizFlowName}时间`"
          required
          :rules="[
            {
              required: true,
              message: `请选择实际${bizFlowName}时间`,
              trigger: 'onBlur'
            }
          ]"
        />

        <!-- 执行状况 -->
        <SwitchingTextarea
          v-model="form.approveContent"
          rows="3"
          required
          :autosize="{ maxHeight: 170 }"
          :label="`执行状况`"
          type="textarea"
          maxlength="2000"
          :placeholder="`请输入执行状况`"
          show-word-limit
          :rules="[
            {
              required: true,
              message: `请输入执行状况`,
              trigger: 'onBlur'
            }
          ]"
        />

        <!-- 附件 -->
        <SwitchingFileUploader
          v-model="form.attachments"
          :accept="FILE_ACCEPT_TYPES.FILE"
          :max-size="1024 * 1024 * 10"
          tip="文件大小不超过 10M"
        />
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import { approveChange } from "../../api";
import TimePickerFormItem from "../TimePickerFormItem.vue";
import cloneDeep from "lodash/cloneDeep";
import SwitchingFileUploader from "../form/SwitchingFileUploader.vue";
import SwitchingTextarea from "../form/SwitchingTextarea.vue";
import { FILE_ACCEPT_TYPES } from "@/components/fileuploader/util";

const defaultForm = {
  actualUseTime: "",
  approveContent: "",
  attachments: []
};

export default {
  components: {
    TimePickerFormItem,
    SwitchingFileUploader,
    SwitchingTextarea
  },
  props: {
    hasPermission: {
      type: Boolean,
      default: false
    },
    bizFlowName: {
      type: String,
      default: ""
    },
    baseInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      form: cloneDeep(defaultForm)
    };
  },
  computed: {
    FILE_ACCEPT_TYPES: () => FILE_ACCEPT_TYPES
  },
  methods: {
    onCountersign() {
      this.visible = true;
      this.form = cloneDeep(defaultForm);
      this.$nextTick(() => {
        this.$refs.form.resetValidation();
      });
    },
    beforeClose(action, done) {
      if (action === "confirm") {
        this.$refs.form
          .validate()
          .then(() => {
            this.loading = true;
            approveChange({ ...this.baseInfo, ...this.form })
              .then(() => {
                this.$toast.success("操作成功");
                this.$emit("on-success");
                this.loading = false;
                done();
              })
              .catch(() => {
                this.loading = false;
                this.$toast.fail("操作失败, 请重试");
                done(false);
              });
          })
          .catch(() => {
            done(false);
          });
      } else {
        done();
      }
    },
    onClosed() {
      this.loading = false;
      this.form = cloneDeep(defaultForm);
    }
  }
};
</script>
