<template>
  <div class="switching-record">
    <van-steps
      active-color="#3278FF"
      direction="vertical"
      :active="list.length"
    >
      <van-step v-for="item in list" :key="item.id">
        <!-- 标题 -->
        <div class="switching-record-name">{{ item.operationTypeName }}</div>

        <!-- 备注/说明 -->
        <div v-if="item.approveContent" class="switching-record-content">
          {{ item.approveContent }}
        </div>
        <!-- 实际操作时间 -->
        <div v-if="item.actualUseTime" class="switching-record-content">
          实际操作时间：{{ item.actualUseTime }}
        </div>
        <!-- 附件 -->
        <div v-if="item.attachmentName" class="switching-record-content">
          附件：
          <span
            v-for="(fileName, index) in item.attachmentName.split(',')"
            :key="index"
            class="switching-record-file"
            @click="download(item.attachmentPath.split(',')[index])"
            >{{ fileName }}</span
          >
        </div>

        <!-- 底部 -->
        <div class="switching-record-footer">
          <span class="switching-record-footer-item">
            <van-icon
              class="switching-record-footer-icon"
              name="yonghu1"
              class-prefix="iconfont"
            />
            <span>{{ item.operationUserName }}</span>
          </span>

          <span class="switching-record-footer-item">
            <van-icon
              class="switching-record-footer-icon"
              name="riqi"
              class-prefix="iconfont"
            />
            <span>{{ item.approveTime }}</span>
          </span>
        </div>
      </van-step>
    </van-steps>
  </div>
</template>

<script>
export default {
  props: {
    records: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    list() {
      const list = [...this.records];
      return list.reverse();
    }
  },
  methods: {
    download(fileId) {
      window.location.href = `${window.globalConfig.VUE_APP_FILE_URL}/file/download?downloadFlag=true&fileName=${fileId}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.switching-record {
  font-family: PingFangSC-Medium, PingFang SC;
  &-name {
    font-size: 14px;
    font-weight: 500;
    color: #2e2e4d;
    line-height: 20px;
    margin-bottom: 4px;
  }

  &-content {
    font-size: 12px;
    font-weight: 400;
    color: #2e2e4d;
    line-height: 18px;
    max-height: 145px;
    overflow: auto;
  }

  &-footer {
    font-size: 12px;
    font-weight: 400;
    color: #8c8f97;
    line-height: 18px;
    margin-top: 8px;
  }

  &-footer-item + &-footer-item {
    margin-left: 17px;
  }

  &-footer-icon {
    margin-right: 5px;
  }

  &-file {
    color: #1676ff;
    word-break: break-all;
    margin-right: 5px;
  }

  ::v-deep {
    .van-step__circle {
      width: 10px;
      height: 10px;
    }

    .van-steps--vertical {
      padding-left: 51px;
    }

    .van-step--vertical .van-step__circle-container,
    .van-step--vertical .van-step__line {
      left: -21px;
    }
  }
}
</style>
