<template>
  <div class="delay-submit">
    <van-button
      style="width: 100%;"
      class="stop-check-btn"
      round
      size="small"
      type="danger"
      @click="onCountersign"
      @closed="onClosed"
      >延期申请</van-button
    >

    <van-dialog
      v-model="visible"
      class="switching-detail-dialog"
      :class="{ loading }"
      title="延期申请"
      show-cancel-button
      :disabled="loading"
      :before-close="beforeClose"
    >
      <van-form ref="form" class="content-box" validate-trigger="onSubmit">
        <!-- 计划投用时间 -->
        <TimePickerFormItem
          v-model="form.planUseTime"
          readonly
          input-align="right"
          is-link
          :label="`计划投用时间`"
          required
          :min-date="minPlanUseTime"
          placeholder="请选择计划投用时间"
          :rules="[
            {
              required: true,
              message: `请选择计划投用时间`,
              trigger: 'onBlur'
            }
          ]"
        />

        <!-- 延期原因 -->
        <SwitchingTextarea
          v-model="form.approveContent"
          rows="3"
          required
          :autosize="{ maxHeight: 170 }"
          label="延期原因"
          type="textarea"
          maxlength="2000"
          placeholder="请输入延期原因"
          show-word-limit
          :rules="[
            {
              required: true,
              message: `请输入延期原因`,
              trigger: 'onBlur'
            }
          ]"
        />

        <!-- 停用性质改变 -->
        <van-field v-if="!changeType" label="停用性质改变">
          <template #input>
            <van-radio-group v-model="form.stopStatus" direction="horizontal">
              <van-radio
                v-for="item in DECOMMISSIONS.filter(v => v.value < 2)"
                :key="item.value"
                :name="item.value"
                >{{ item.label }}</van-radio
              >
            </van-radio-group>
          </template>
        </van-field>
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import TimePickerFormItem from "../TimePickerFormItem.vue";
import SwitchingTextarea from "../form/SwitchingTextarea.vue";
import { approveChange } from "../../api";
import { DECOMMISSIONS } from "../../utils";
import dayjs from "dayjs";

const defaultForm = {
  planUseTime: "",
  approveContent: "",
  stopStatus: ""
};

export default {
  components: {
    TimePickerFormItem,
    SwitchingTextarea
  },
  props: {
    baseInfo: {
      type: Object,
      default: () => ({})
    },
    changeType: null,
    actualStopTime: null
  },
  data() {
    return {
      visible: false,
      loading: false,
      executorVisiable: false,
      form: { ...defaultForm }
    };
  },
  computed: {
    DECOMMISSIONS: () => DECOMMISSIONS,
    chooseValue() {
      if (!this.form.executorId) return [];
      return [{ value: this.form.executorId, label: this.executorName }];
    },
    minPlanUseTime() {
      // 计划投用时间要迟与实际投用时间
      if (this.actualStopTime && this.changeType !== 3)
        return dayjs(this.actualStopTime).toDate();
      return undefined;
    }
  },
  methods: {
    onCountersign() {
      this.visible = true;
      this.form = { ...defaultForm, stopStatus: this.baseInfo.stopStatus };
      this.$nextTick(() => {
        this.$refs.form.resetValidation();
      });
    },
    beforeClose(action, done) {
      if (action === "confirm") {
        this.$refs.form
          .validate()
          .then(() => {
            this.loading = true;
            approveChange({ ...this.baseInfo, ...this.form, approveStatus: 4 })
              .then(() => {
                this.$toast.success("操作成功");
                this.$emit("on-success");
                this.loading = false;
                done();
              })
              .catch(() => {
                this.loading = false;
                this.$toast.fail("操作失败, 请重试");
                done(false);
              });
          })
          .catch(() => {
            done(false);
          });
      } else {
        done();
      }
    },
    onClosed() {
      this.loading = false;
      this.form = { ...defaultForm };
      this.executorName = "";
    },
    onChooseExecutor(ids = [], list = []) {
      const item = list[0] || {};
      this.form.executorId = item.value;
      this.executorName = item.label;
    }
  }
};
</script>
