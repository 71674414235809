var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"flex-grow":"1"}},[(_vm.hasPermission)?_c('van-button',{staticStyle:{"width":"100%"},attrs:{"round":"","size":"small","type":"info"},on:{"click":_vm.onCountersign,"closed":_vm.onClosed}},[_vm._v(_vm._s(_vm.bizFlowName))]):_vm._e(),_c('van-dialog',{staticClass:"switching-detail-dialog",class:{ loading: _vm.loading },attrs:{"title":_vm.bizFlowName,"show-cancel-button":"","disabled":_vm.loading,"before-close":_vm.beforeClose},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('van-form',{ref:"form",staticClass:"content-box",attrs:{"validate-trigger":"onSubmit"}},[_c('van-field',{attrs:{"label":"执行人员","placeholder":"请选择执行人员","required":"","value":_vm.form.executorId ? _vm.executorName : '',"readonly":"","input-align":"right","is-link":"","rules":[
          {
            required: true,
            message: "请选择执行人员",
            trigger: 'onBlur'
          }
        ]},on:{"click":function($event){_vm.executorVisiable = true}}}),_c('select-person',{attrs:{"title":"执行人员","visible":_vm.executorVisiable,"position":"bottom","multiple":false,"choose-value":_vm.chooseValue},on:{"close":function($event){_vm.executorVisiable = false},"confirm":_vm.onChooseExecutor}}),_c('SwitchingTextarea',{attrs:{"rows":"3","required":"","autosize":{ maxHeight: 170 },"label":"`备注","type":"textarea","maxlength":"2000","placeholder":"请输入备注","show-word-limit":"","rules":[
          {
            required: true,
            message: "请输入备注",
            trigger: 'onBlur'
          }
        ]},model:{value:(_vm.form.approveContent),callback:function ($$v) {_vm.$set(_vm.form, "approveContent", $$v)},expression:"form.approveContent"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }