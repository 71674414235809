<template>
  <van-field
    v-bind="$attrs"
    type="textarea"
    class="switching-textarea"
    :error="false"
    v-on="$listeners"
  />
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.switching-textarea {
  display: block;
  ::v-deep {
    .van-cell__value {
      border: 1px solid #e1e3e8;
      margin-top: 8px;
      padding: 8px 10px 0;
      .van-field__word-limit {
        padding: 0 0 3px;
      }
    }
  }
}
</style>
