<template>
  <div>
    <van-search
      v-model="data.keyWord"
      placeholder="请输入联锁回路工作票或名称"
      @search="onSearch"
      @clear="onSearch"
    >
      <template #left-icon>
        <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
      </template>
    </van-search>

    <van-search
      v-model="departmentName"
      left-icon="shouqi"
      readonly
      icon-prefix="iconfont"
      placeholder="所属部门"
      class="select-department"
      @click="departmentVisible = true"
    >
      <template #left-icon>
        <van-icon name="shouqi" class-prefix="iconfont" class="search-icon" />
      </template>
    </van-search>

    <cascade-department
      ref="cascadeDepartment"
      v-model="data.department"
      include-children
      :visible="departmentVisible"
      @close="departmentVisible = false"
      @confirm="onDepartmentChange"
    />

    <SwitchingQuery :query="data" @refresh="onSearch" />
  </div>
</template>

<script>
import SwitchingQuery from "./SwitchingQuery.vue";
export default {
  components: {
    SwitchingQuery
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      departmentVisible: false,
      departmentName: "",
      subDepartment: true
    };
  },
  methods: {
    onDepartmentChange(val, includeInfo) {
      this.departmentName = val.label;
      this.subDepartment = includeInfo?.include;
      this.onSearch();
    },
    onSearch() {
      this.$emit("on-search");
    }
  }
};
</script>

<style lang="scss" scoped>
.select-department {
  .search-icon {
    font-size: 12px;
  }
}
</style>
