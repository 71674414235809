<template>
  <div style="flex-grow: 1;">
    <van-button
      v-if="hasPermission"
      style="width: 100%;"
      round
      size="small"
      type="info"
      @click="onCountersign"
      @closed="onClosed"
      >{{ bizFlowName }}</van-button
    >

    <van-dialog
      v-model="visible"
      class="switching-detail-dialog"
      :class="{ loading }"
      :title="bizFlowName"
      show-cancel-button
      :disabled="loading"
      :before-close="beforeClose"
    >
      <van-form ref="form" class="content-box" validate-trigger="onSubmit">
        <!-- 执行人员 -->
        <van-field
          label="执行人员"
          placeholder="请选择执行人员"
          required
          :value="form.executorId ? executorName : ''"
          readonly
          input-align="right"
          is-link
          :rules="[
            {
              required: true,
              message: `请选择执行人员`,
              trigger: 'onBlur'
            }
          ]"
          @click="executorVisiable = true"
        />

        <select-person
          title="执行人员"
          :visible="executorVisiable"
          position="bottom"
          :multiple="false"
          :choose-value="chooseValue"
          @close="executorVisiable = false"
          @confirm="onChooseExecutor"
        />

        <!-- 备注 -->
        <SwitchingTextarea
          v-model="form.approveContent"
          rows="3"
          required
          :autosize="{ maxHeight: 170 }"
          label="`备注"
          type="textarea"
          maxlength="2000"
          placeholder="请输入备注"
          show-word-limit
          :rules="[
            {
              required: true,
              message: `请输入备注`,
              trigger: 'onBlur'
            }
          ]"
        />
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import SelectPerson from "@/components/SelectPerson.vue";
import SwitchingTextarea from "../form/SwitchingTextarea.vue";
import { approveChange } from "../../api";

const defaultForm = {
  executorId: "",
  approveContent: ""
};

export default {
  components: {
    SelectPerson,
    SwitchingTextarea
  },
  props: {
    hasPermission: {
      type: Boolean,
      default: false
    },
    bizFlowName: {
      type: String,
      default: ""
    },
    baseInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      executorVisiable: false,
      form: { ...defaultForm }
    };
  },
  computed: {
    chooseValue() {
      if (!this.form.executorId) return [];
      return [{ value: this.form.executorId, label: this.executorName }];
    }
  },
  methods: {
    onCountersign() {
      this.visible = true;
      this.form = { ...defaultForm };
      this.$nextTick(() => {
        this.$refs.form.resetValidation();
      });
    },
    beforeClose(action, done) {
      if (action === "confirm") {
        this.$refs.form
          .validate()
          .then(() => {
            this.loading = true;
            approveChange({ ...this.baseInfo, ...this.form, approveStatus: 1 })
              .then(() => {
                this.$toast.success("操作成功");
                this.$emit("on-success");
                this.loading = false;
                done();
              })
              .catch(() => {
                this.loading = false;
                this.$toast.fail("操作失败, 请重试");
                done(false);
              });
          })
          .catch(() => {
            done(false);
          });
      } else {
        done();
      }
    },
    onClosed() {
      this.loading = false;
      this.form = { ...defaultForm };
      this.executorName = "";
    },
    onChooseExecutor(ids = [], list = []) {
      const item = list[0] || {};
      this.form.executorId = item.value;
      this.executorName = item.label;
    }
  }
};
</script>
