<template>
  <div class="put-submit">
    <DelaySubmit
      v-if="data.applyUserId === userInfo.id"
      class="put-submit-btn"
      :base-info="baseInfo"
      :actual-stop-time="data.actualStopTime"
      :change-type="data.changeType"
      @on-success="$emit('on-success')"
    />

    <WaitStopAssign
      class="put-submit-btn"
      :has-permission="hasPermission"
      :biz-flow-name="bizFlowName"
      :base-info="baseInfo"
      @on-success="$emit('on-success')"
    />
  </div>
</template>

<script>
import WaitStopAssign from "./WaitStopAssign.vue";
import DelaySubmit from "./DelaySubmit.vue";

export default {
  components: {
    WaitStopAssign,
    DelaySubmit
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    hasPermission: {
      type: Boolean,
      default: false
    },
    bizFlowName: {
      type: String,
      default: ""
    },
    baseInfo: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style lang="scss" scoped>
.put-submit {
  flex-grow: 1;
  display: flex;

  &-btn {
    width: 50%;
    flex-grow: 1;
  }
  &-btn + &-btn {
    margin-left: 13px;
  }
}
</style>
